/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap');
body{font-family: 'Poppins', sans-serif} */

.main-content {
  display: flex;
  height: 100%;
  min-height: calc(100vh - 82px);
  border-top: 1px solid #9f9f9f;
}


.content-section {
  margin: 0.5rem;
  width: 100%;
}

.secondary-colors {
  background-color: #eaeaeb;
  color: #191818;
  border: none;
}

.form-control.is-valid,
.was-validated .form-control:valid+.input-group-text {
  border-color: #198754;
}

.form-control.is-invalid,
.was-validated .form-control:invalid+.input-group-text {
  border-color: #dc3545;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f7f7f7;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #484c7f;
}

.shadow {
  background-color: rgb(246, 244, 244);

}

.form-control-lg {

  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 0.5rem;
}

.custom-select {
  height: 30px;
  /* Adjust the height as needed */
  padding: 2px;
  /* Adjust the padding as needed */
  font-size: 15px;
  width: 95px;
  /* Adjust the font size as needed */
}

.form-control {
  border-radius: 0.0rem;
}

Button {
  font-weight: 600 !important;
}

.addLabel {
  font-weight: bold;
  font-size: 1.2vw;
}

.import-modal {
  height: 300px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 0px;
  cursor: pointer;
  position: relative;
}

.import-border {
  border: #0D509C;
  background: #dbebff;
  border-style: dashed;
  width: 400px;
  height: 300px;
}

.import-logo {
  margin-top: 40px;
  width: 200px;
  cursor: pointer;
}

.chatBackground {
  background-image: url("./Assets/watsap.png");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;

}

.import-modal input {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.update-profile {
  --bs-btn-color: #000;
  --bs-btn-bg: "transperant";
  --bs-btn-border-color: "transperant";
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #484c7f;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0a58ca;
  --bs-btn-active-border-color: #0a53be;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.calendar-container {
  position: relative;
  display: flex;

}

.breakText {
  display: inline-block;
  width: 150px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.img-thumbnai {
  padding: .25rem !important;
}

.breakTextEmp {
  display: inline-block;
  width: 220px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.calendar-container .react-calendar {
  position: absolute;
  top: 100%;
  left: 50%;
  width: auto;
  transform: translateX(-50%);
  z-index: 1;
}

abbr[title] {

  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
  color: black;
  width: auto;
  display: flex;
}

.react-calendar__navigation button {
  min-width: 4px;
  background: none;
}

.calendar {
  padding: 0;
}

.paragrapha {
  color: #ccc;
  border-bottom: 1px dashed #ccc;
  width: fit-content;
}

.paragraph {
  color: #151414;
  border-bottom: 1px dashed #ccc;
  width: fit-content;
}

.pointer {
  cursor: pointer;

}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  text-align: end;

}

.page-item {
  margin: 5px 0px;


}


.page-item button {

  border: none;
  background-color: transparent;
  cursor: pointer;
}


.page-item.active button {
  font-weight: bold;
  background: linear-gradient(to bottom, rgba(0, 123, 255, 0.2), rgba(0, 123, 255, 0.7));
  color: #000;
}

.pagination-container {
  padding: 0px 15px;
  display: flex;
  justify-content: end;
}

.page-link {
  color: black;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th,
td {
  border-bottom: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}




.selected {
  background-color: #f4f1f1;
}

.approved-leave {
  background-color: #ccc;
  border: 1px solid #999;
  color: #333;
  pointer-events: none;

}

.fc-title-month {
  width: 20px;
  font-size: 12px;
  display: block;
  text-align: center;
  margin-top: 2px;
  color: #fff;
  background-color: #333;
  padding: 2px 4px;
  border-radius: 3px;
  white-space: nowrap;
  overflow: none;
  text-overflow: scroll;
}

.fc-content {
  display: block;
  flex-direction: column;
}

.fc-content span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 2px;
}

.fc-title-container {
  display: flex;
  flex-direction: column;
}

.fc-employee-name,
.fc-leave-type {
  white-space: normal;
}

.current-month-leave {
  background-color: #007F73;
}

.hover-animate-image {
  transition: transform 0.3s ease-in-out;
}

.hover-animate-image:hover {
  transform: scale(1.1);
}





.mar {
  padding-top: 100%;
  margin: 10px;
  animation: marquee 5s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

.holiday-optional {
  color: red;
}


.holiday-mandatory {
  color: yellow;
}


.red-border {
  border-color: red;
}

/* .primary-colors{
  background-color: #ffff;
  color: #000;
} */
/* .primary-colors{
  background-color: #484c7f;
  color: #fff;
} */


.css-1o0wxxh>.ps-menu-button {
  color: #000;
}

.css-1indtr3>.ps-menu-button {
  color: #000;
}


.navbar-expanded {
  width: 150px;
}

.navbar-collapsed {
  width: 150px;
}

.with-line::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 123, 255, 0.2), rgba(0, 123, 255, 0.7));

  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}



.fc .fc-scrollgrid-liquid {
  height: 108% !important;
}

.react-datepicker-wrapper {
  display: flex !important;
  width: 100%;
}

.fc .fc-toolbar-title {
  font-size: 1.09em !important;
  margin: 0px !important;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #fc0505;
  border-radius: 0px !important;
  line-height: 25px;
  height: 40px !important;
  width: 32% !important;

}


/* .theme-light {
  --background-color: #ffffff;
  --text-color: #000000;
}

.theme-dark {
  --background-color: #333333;
  --text-color: #ffffff;
} */

.image-container {
  position: absolute;

}

.rounded-image {
  position: absolute;
  border-radius: 50%;
  border: 2px solid #fff;
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.count {
  position: relative;
  left: 42px;
  /* transform: translateX(-50%); */
  border-radius: 50%;
  /* border: 2px solid white; */
  width: 15px;
  height: 15px;
  top: -3px;
}

.rounded-image:nth-child(1) {
  top: 1px;
  left: 30px;
}

.rounded-image:nth-child(2) {
  top: 1px;
  left: 22px;
}

.rounded-image:nth-child(4) {
  top: 1px;
  left: 45px;
}




body {
  background-color: var(--background-color);
  color: var(--text-color);
}

.message-list {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  max-width: 100%;
  /* Adjust as needed */
  word-wrap: break-word;
}

.theme-light {
  --background-color: #fff;
  --background-color2: #ffffff;
  --text-color: #000000;
}

/* Dark Theme */
.theme-dark {
  --background-color: #2a2929;
  --background-color2: #000000;
  --text-color: #f7f7f7;


}

.table-light {
  background-color: var(--background-color);
  color: var(--text-color);
}

.table-dark {
  background-color: var(--background-color);
  color: var(--text-color);
}

.ps-submenu-content {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

.card-header {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

.card-footer {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

.card-body {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

.th {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

.footer {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

.update-profile {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;

}

.card {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

/* .table>:not(caption)>*>* {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
} */

.modal-header {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

.modal-body {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

.modal-footer {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

.btncolorss {
  color: var(--text-color) !important;
}

.addtime {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

.totalemp {
  background-color: #FFB4B4 !important;
  border-radius: 10px;

}

.totalleave {
  background-color: #FFE691 !important;
  border-radius: 10px;
}

.totalproject {
  background-color: #F1BB9D !important;
  border-radius: 10px;
}

.totalclient {
  background-color: #CEE8CA !important;
  border-radius: 10px;
}

.totalworklocations {
  background-color: #8EC3E5 !important;
  border-radius: 10px;
}

.total {
  background-color: var(--background-color2) !important;
  color: var(--text-color) !important;
}

.totalmonth {
  color: var(--text-color) !important;
}

.custom-chart1 title {
  color: #00AA4F;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.js-plotly-plot .plotly .modebar-btn {
  display: none !important;
}

.background-image {
  background-image: url('./Assets/loginz_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.transparent-card {
  background-color: transparent !important;
}

.background-shade {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;

}

.tfoot {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.dropdown-menu {
  --bs-dropdown-min-width: 18rem !important;
}



.actionFont {
  font-size: 10px;
}

.actionIcon:hover {
  background-color: #bce8f5;
  height: fit-content;
  border-radius: 10px;
  cursor: pointer;
}

.techCard {
  box-shadow: 1px 2px 2px 2px rgba(8, 8, 8, 0.25);
  transition: 0.2s;
}

.techCard:hover {
  box-shadow: 1px 2px 2px 2px rgba(190, 49, 49, 0.25);
  /* background-color: #000; */
}


.input-container {
  position: relative;
}

.input-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-60%);

}


#input1 {
  padding-left: 40px;

}




.payslip-container {
  display: flex;
  justify-content: space-between;
}

.payslip-section {
  flex: 0 0 48%;
  margin-bottom: 2px;
}

.section-header {
  text-align: center;
}

.table-container {
  max-height: 500px;
  overflow-y: auto;
}

.fc .fc-popover-title {

  background-color: var(--background-color2) !important;
  color: var(--text-color) !important;

}

.input-heights::placeholder {
  font-size: 14px;

}

/* .table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
} */

/* .table th {
  background-color: #f2f2f2;
} */

/* .table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
} */

.fc-event {
  border: none;
  width: auto;
}

.table-container {
  overflow-x: auto;
  max-width: 100%;
}

.css-13cymwt-control {
  border-radius: 0px !important;
}

.css-t3ipsp-control:hover {
  border-radius: 0px !important;
}

.css-t3ipsp-control {
  border-radius: 0px !important;
}

.profileView {
  width: 150px;
  min-width: 150px;
  height: 150px;
  border-radius: 50% !important;
}

.profileCard {
  width: 6vw;
  /* min-width: 150px; */
  height: 6vw;
  border-radius: 50% !important;
}

.profileTable {
  width: 4vw;
  height: 4vw;
  border-radius: 50% !important;
}

.profile-widget {
  background-color: #f4f4f4;
  /* border: 1px solid #E9E9EA; */
  border-radius: 10px;
  margin-bottom: 30px;
  min-height: 15vw;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;
}

.profile-widgetDes {
  background-color: #f4f4f4;
  /* border: 1px solid #E9E9EA; */
  border-radius: 10px;
  margin-bottom: 30px;
  height: 15vw;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;

}

.profile-widgetLookup {
  background-color: #f4f4f4;
  /* border: 1px solid #E9E9EA; */
  border-radius: 10px;
  margin-bottom: 30px;
  height: 15vw;
  overflow-y: auto;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;

}

.profile-widgetAnounce {
  background-color: #f4f4f4;
  /* border: 1px solid #E9E9EA; */
  border-radius: 10px;
  margin-bottom: 30px;
  height: 15vw;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;
}


/* General styling for the dashboard */
.attendance-dashboard {
  font-family: 'Arial', sans-serif;
  padding: 20px;
  background-color: #f9f9f9;
  color: #333;
}

.row-container {
  display: flex;
  justify-content: space-between;
 }

 .timesheet, .statistics, .today-activity {
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1;  
  margin: 5px;
}

.timesheet {
  flex: 1;
}

.statistics {
  flex: 2;
}

.today-activity {
  flex: 1;
}

 .btn-punch-in, .btn-punch-out {
  padding: 10px 40px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-punch-in {
  background-color: #28a745;
  color: white;
}

.btn-punch-out {
  background-color: #dc3545;
  color: white;
}

.btn-punch-in:hover {
  background-color: #218838;
}

.btn-punch-out:hover {
  background-color: #c82333;
}

 
.today-activity {
  font-size: 16px;
}

.today-activity div {
  margin-bottom: 5px;
}

.today-activity div span {
  display: block;
  margin-bottom: 3px;
  color: #555;
}
.clock-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;  
  height: 60px;  
  border: 5px solid #939191;  
  border-radius: 10px;  
  background-color: #f7f5f5;  
  font-family: 'Courier New', Courier, monospace;  
  font-size: 24px;  
  color: #721c24;  
  margin-top: 20px;
}

.clock {
  
  font-size: 24px;
  font-weight: bold;
  color: #007bff;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.header .logo {
  width: 130px;
  height: 130px;
  margin-bottom: 1px;
 
}

.header h4 {
  margin: 5px 0;
}

.header p {
  margin: 3px 0;
}

.employee-details, .salary-details, .totals {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.employee-details .details-left, .employee-details .details-right {
  width: 45%;
}

.salary-details .earnings, .salary-details .deductions {
  width: 45%;
}

.salary-details h3 {
  text-align: center;
  margin-bottom: 10px;
}

.totals {
  text-align: center;
}

hr {
  margin: 20px 0;
}

.footer-note {
  text-align: center;
  font-size: 0.9em;
}
.employee-details {
  
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.details-column {
  width: 40%;
}

.details-column p {
  display: flex;
  justify-content: space-between;
}

.details-column p strong {
  width: 40%;
}
.salary-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
 

}

.salary-table th, .salary-table td {
  padding: 8px;
  text-align: left;
  
  
  border-collapse: collapse;
}
.salary-table td.deductions-description,
.salary-table td.deductions-amount {
  padding-left: 20px; /* Adjust this value as needed */
}

.salary-table th {
  background-color: #f2f2f2;
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
 
}

.totals {
  text-align: center;
}
.pay-border{
  border-bottom: none;
}
.cards{
  box-sizing: border-box;
  background-color: #f2f2f2;
  padding: 20px;

}
.statistics-container {
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
}
.stat-item {
  margin-bottom: 10px;
}
.stat-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 5px;
}

.stat-fill {
  height: 5px;
}
.stat-label {
  text-align: right;
}
.today-activity div{
  margin-bottom: 0px;
}

/* all attendace sheet */
.employee-attendance {
  margin: 10px;
  
}

.filters {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.filters input,
.filters select,
.filters button {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.attendanceTable {
  width: 100%;
  overflow-x: auto;
  border-collapse: collapse;
}

.attendanceTableHead {
  background-color: #f4f4f4;
  position: sticky;
  top: 0;
  z-index: 2;
}

.attendanceTh {
  padding: 10px;
  border: none;
  text-align: center;
}

.stickyLeft {
  background-color: white;
  position: sticky;
  left: 0;
  z-index: 3; /* Higher z-index to ensure it stays above scrolling content */
}
.stickyTop {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 3; /* Higher z-index to ensure it stays above scrolling content */
}


.check-icon {
  color: green;
}

.cross-icon {
  color: red;
}
.weekend {
  color: red;
}

.tablerow:nth-child(even) {
  background-color: white ;   
  color: #000; 
}
.attendance-graph {
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: white;
}
  

.heading {
  color: green;
  margin-bottom: 10px;
}

.date-picker {
  margin-bottom: 20px;
  box-shadow: 2px solid grey;
}

