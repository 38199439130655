#change-password-form .input-group-text {
  background-color: #fff;
  border-left: none;
}
[name="opassword"],
[name="npassword"] {
  border-right: none;
}

.forgot-card .card-header {
  background-color: #fff;
  border-bottom: none;
}

.forgot-card .card-header a {
  text-decoration: none;
}

.forgot-password {
  color: #fff;
  text-decoration: none;
}

.forgot-password:hover {
  color: #f19828;
}

#login-form .input-heigt,
#change-password-form .input-heigt,
#forgot_password_form .input-heigt {
  min-height: 3.5rem;
  font-size: 1rem;
}
.btn-size {
  padding: 0.85rem;
  font-size: 1rem;
}

#login-form .input-group-text {
  background-color: #fff;
  border-left: none;
  border-radius: 0px 10px 10px 0px;
}
#login-form [name="password"] {
  border-right: none;
}

#reset-password-form .input-group-text {
  background-color: #fff;
  border-left: none;
}
[name="opassword"],
[name="npassword"] {
  border-right: none;
}
.password {
  border-radius: 0.5rem 0 0 0.5rem;
}
#login-form .invalid-feedback{
  display: block;
}


.divider:after,
.divider:before {
content: "";
flex: 1;
height: 1px;
background: #eee;
}
.h-custom {
height: calc(100% - 73px);
}
@media (max-width: 450px) {
.h-custom {
height: 100%;
}
.iframe{
  height: 75vw;
}
}
@media (min-width:451px) and (max-width:768px){
  .iframe{
    height: 50vh;
  }
}
@media (min-width:769px){
  .iframe{
    height:75vh;
    /* background-color: red; */
    /* background-color: #f19828; */
  }
 
}
