.loading-indicator:before {
  content: '';
  background: #f1efefcc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1; /* Lower z-index for back */
}

.loading-indicator:after {
  content: '';
  position: fixed;
  width: 90px;
  height: 110px;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  z-index: 9999; /* Higher z-index for front */
  background-image: url('../Assets/Spinner-4.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
